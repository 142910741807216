import { Flex, Grid, Tag, Text } from '@chakra-ui/react'

import { formatDateTime } from '@/utils/formatDateTime'
import { formatFullName } from '@/utils/formats'

import { QuickReportDetailT } from '../types/types'

interface QuickReportDetailIProps {
  detail: QuickReportDetailT
  incidentCategory?: string
}

export const QuickReportDetail = ({
  detail,
  incidentCategory,
}: QuickReportDetailIProps) => {
  const shouldShowReportingPerson =
    !!detail?.firstName ||
    !!detail?.lastName ||
    !!detail?.email ||
    !!detail?.locationOfConcern ||
    !!detail?.additionalContactInformation
  const shouldShowLabel = !!incidentCategory
  const shouldShowPartiesInvolved = !!detail?.partiesInvolved
  const shouldShowTimeReported = !!detail.timeReported
  const shouldShowAreaOfConcern = !!detail.locationOfConcern

  return (
    <Grid gap='20px 0px' mb='20px' mt='20px' templateColumns='175px 1fr'>
      {shouldShowReportingPerson && (
        <QuickReportDetailRow
          label='Reporting Person'
          value={<ReportingPersonDetail detail={detail} />}
        />
      )}
      {shouldShowLabel && (
        <QuickReportDetailRow
          label='Label'
          value={
            <Tag
              bgColor='#cba80dd4'
              borderRadius='10px'
              color='white'
              px='16px'
              py='4px'
            >
              {incidentCategory}
            </Tag>
          }
        />
      )}
      {shouldShowPartiesInvolved && (
        <QuickReportDetailRow
          label='Parties Involved'
          value={detail?.partiesInvolved}
        />
      )}
      {shouldShowTimeReported && (
        <QuickReportDetailRow
          label='Time Reported'
          value={formatDateTime(detail?.timeReported)}
        />
      )}
      {shouldShowAreaOfConcern && (
        <QuickReportDetailRow
          label='Area of Concern'
          value={detail?.locationOfConcern}
        />
      )}
    </Grid>
  )
}

interface QuickReportDetailRowIProps {
  label: string
  value: React.ReactNode
}

const QuickReportDetailRow = ({ label, value }: QuickReportDetailRowIProps) => (
  <>
    <Text fontSize='14px' fontWeight={700}>
      {label}
    </Text>
    <Text fontSize='14px' fontWeight={500}>
      {value}
    </Text>
  </>
)

const ReportingPersonDetail = ({ detail }: { detail: QuickReportDetailT }) => {
  const hasName = !!detail?.firstName || !!detail?.lastName
  const hasEmail = !!detail?.email
  const hasPhoneNumber = !!detail?.phoneNumber
  const hasContactInfo = !!detail?.additionalContactInformation

  return (
    <Flex direction='column' gap='5px'>
      {hasName && <Text>{formatFullName(detail)}</Text>}
      {hasEmail && <Text>{detail?.email}</Text>}
      {hasPhoneNumber && <Text>{detail?.phoneNumber}</Text>}
      {hasContactInfo && <Text>{detail?.additionalContactInformation}</Text>}
    </Flex>
  )
}
