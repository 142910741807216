import { IncidentQueryResult } from '@/graphql/generated/hooks'
import { IncidentQuery } from '@/graphql/generated/operations'

export type IncidentNotesMediaType =
  IncidentQueryResult['data']['incident']['notes']['edges']['0']['node']['media']['0']

export interface IncidentNoteMediaIProps {
  files: IncidentNotesMediaType[]
}

export interface RenderMediaFileIProps {
  file: IncidentNotesMediaType
  onFileClick: (file: IncidentNotesMediaType) => void
}

export enum NonActionItem {
  IncidentNote = 'IncidentNote',
  AudioMessage = 'AudioMessage',
  SystemAction = 'SystemAction',
}

export type IncidentActionType =
  IncidentQueryResult['data']['incident']['actions']['0']

export type IncidentEventType =
  IncidentQueryResult['data']['incident']['events']

export type IncidentAudioMessageType =
  IncidentQueryResult['data']['incident']['audioMessages']['edges']['0']['node']

export type IncidentNoteType =
  IncidentQueryResult['data']['incident']['notes']['edges']['0']['node']

export enum TimelineOrder {
  Asc = 'Asc',
  Desc = 'Desc',
}

export type QuickReportDetailT = IncidentQuery['incident']['externalDetail']
