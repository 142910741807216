import { FacilityListType } from '@/pages/facilities'

export const filterFacilities = (
  facilitiesList: FacilityListType, /// facilitiesList can be undefined sometimes despite the type
  filter: string,
  hasActiveIncidents: boolean
): FacilityListType => {
  const lowerTrimmedTerm = filter.trim().toLocaleLowerCase()
  return (
    facilitiesList
      ?.filter(
        (f) =>
          f?.node?.name.toLocaleLowerCase().includes(lowerTrimmedTerm) ||
          f?.node?.address
            ?.join(', ')
            .toLocaleLowerCase()
            .includes(lowerTrimmedTerm) ||
          filter === ''
      )
      .filter((f) => {
        // If hasActiveIncidents actually is a bool we could simplify this check.
        // However, the tests indicate that it can also be undefined, while the typing contradicts this
        if (hasActiveIncidents) {
          return f?.node?.incidents?.totalCount > 0
        } else if (hasActiveIncidents == false) {
          return f?.node?.incidents?.totalCount === 0
        }
        return true
      }) || []
  )
}
